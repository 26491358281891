import { environments } from "@coxauto-ui/ccxm-util-derive-environment";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import OktaAuth from "@okta/okta-auth-js";
import {
  ComponentType,
  LazyExoticComponent,
  Suspense,
  lazy,
  useEffect,
  useState
} from "react";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import { getEnvConfig } from "@coxauto-ui/core/get-env";
import { CxmEnvironmentValues } from "@coxauto-ui/ccxm/interfaces";
import { AdminEnvironmentValues } from "@coxauto-ui/tenant/interfaces";

export enum HostEnvironment {
  None,
  Unknown,
  Local,
  Dev,
  Dev2,
  QA,
  UAT,
  DarkProd,
  Prod
}

interface NewDealModuleProps {
  commonOrgId: string;
  oktaAuth: OktaAuth;
  environment: HostEnvironment;
  canDesk?: boolean;
  getAuthOverride?: () => Promise<string>;
  onCancel?: () => void;
  onCompleted?: () => void;
}

const NewDealModule: LazyExoticComponent<ComponentType<NewDealModuleProps>> =
  lazy(() => loadRemoteModule("open-deal", "./NewDealModule"));

export const getEnvironment = () => {
  const environmentProperty = {
    [environments.prod]: HostEnvironment.Prod,
    [environments.nonprod]: HostEnvironment.QA
  };

  const { ENV } = getEnvConfig<CxmEnvironmentValues | AdminEnvironmentValues>(
    "cxm"
  );

  return environmentProperty[ENV];
};

export const LoadNewDealModule = (
  props: Omit<NewDealModuleProps, "environment" | "oktaAuth">
) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const refreshAuthState = async () => {
      try {
        await authClient.getAuth();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    refreshAuthState();

    const tokenExpirationHandler = refreshAuthState;

    authClient.oktaAuth.tokenManager.on("expired", tokenExpirationHandler);

    return () => {
      authClient.oktaAuth.tokenManager.off("expired", tokenExpirationHandler);
    };
  }, [authClient]);

  return (
    <Suspense fallback={<div>Loading New Deal App ...</div>}>
      {!isAuthenticated ? (
        <LoadingIndicator data-testid="loading-authentication-indicator" />
      ) : (
        <NewDealModule
          environment={getEnvironment()}
          getAuthOverride={authClient.getAuth}
          oktaAuth={authClient.oktaAuth}
          {...props}
        />
      )}
    </Suspense>
  );
};

export default LoadNewDealModule;
