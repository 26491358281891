import { fetchWrapper } from "@coxauto-ui/core/http";
import {
  AdminEnvironmentValues,
  CommonOrgDealerRequest,
  DealInspectorResponse,
  DealerConfiguration,
  DealershipRequest,
  LinkRequest,
  UserByUsernameRequest,
  UserListRequest
} from "@coxauto-ui/tenant/interfaces";
import authClient from "@coxauto-ui/core/auth/get-auth-client-tenant-admin";
import { getEnvConfig } from "@coxauto-ui/core/get-env";

const { BASE_URL: baseUrl } = getEnvConfig<AdminEnvironmentValues>("admin");

export async function getEmployees(nextUrl?: string) {
  const url = nextUrl || `${baseUrl}api/admin/users/employees?skip=0&take=25`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (!response.data || response.hasError) {
    return Promise.reject(response);
  }
  return response.data;
}

export async function getDealers(nextUrl?: string) {
  const url = nextUrl || `${baseUrl}api/admin/dealerships?skip=0&take=25`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (!response.data || response.hasError) {
    return Promise.reject(response);
  }
  return response.data;
}

export async function postDealerConfiguration(
  dealerConfiguration: DealerConfiguration
) {
  const url = `${baseUrl}api/admin/dealerships/configuration`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.postAsync<any>(url, dealerConfiguration, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (!response.data || response.hasError) {
    return Promise.reject(response);
  }
  return response.data;
}

export async function getLinkRequest(request: LinkRequest) {
  const { userName, principal } = request;
  const url = `${baseUrl}api/admin/users/link/${principal}/username/${userName}`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (!response.data || response.hasError) {
    return Promise.reject(response);
  }
  return response.data;
}

export async function addDealer(commonOrgDealer: CommonOrgDealerRequest) {
  const url = `${baseUrl}api/admin/dealerships/add/`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.postAsync<any>(url, commonOrgDealer, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response);
  }
  return response.data;
}

export async function searchDealers(dealershipId: string) {
  const principalsConfig: CallSearchByDealershipId[] = [
    { principal: "commonCore", dealershipId },
    { principal: "vinsolutions", dealershipId }
  ];
  const principalPromises = principalsConfig.map(callSearchByDealershipId);
  const results = await Promise.all(principalPromises);
  return results.flat();
}

type CallSearchByDealershipId = {
  principal: string;
  dealershipId: string;
};

async function callSearchByDealershipId({
  principal,
  dealershipId
}: CallSearchByDealershipId) {
  const url = `${baseUrl}api/admin/dealerships/id/${principal}/${dealershipId}`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });

  if ((!response.data || response.hasError) && response.statusCode !== 404) {
    return Promise.reject(response?.data?.message || response);
  }

  if (response.statusCode === 404) {
    return [];
  }
  return response.data;
}

export async function getDealerStats() {
  const url = `${baseUrl}api/admin/dealerships/stats`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });

  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response);
  }

  return response.data;
}

export async function getDealershipRequest(request: DealershipRequest) {
  const { dealerGroupId, principal } = request;
  const url = `${baseUrl}api/admin/dealerships/id/${principal}/${dealerGroupId}`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });

  if (response.statusCode === 404) {
    return null;
  }

  if (!response.data || response.hasError) {
    return Promise.reject(response);
  }

  return response.data;
}

export async function getUnlinkRequest(request: LinkRequest) {
  const { userName, principal } = request;
  const url = `${baseUrl}api/admin/users/unlink/${principal}/username/${userName}`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });

  if (!response.data || response.hasError) {
    return Promise.reject(response);
  }

  return response.data;
}

export async function getUserList(request: UserListRequest) {
  const { principal, dealershipId } = request;
  const url = `${baseUrl}api/admin/dealerships/id/${principal}/${dealershipId}/users`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (response.statusCode === 404) {
    return null;
  }
  if (!response.data || response.hasError) {
    return Promise.reject(new Error(response?.statusCode?.toString()));
  }
  return response.data;
}

export async function getUserByUsername(request: UserByUsernameRequest) {
  const { principal, username } = request;
  const url = `${baseUrl}api/admin/users/${principal}/username/${username}`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (response.statusCode === 404) {
    return null;
  }
  if (!response.data || response.hasError) {
    return Promise.reject(new Error(response?.statusCode?.toString()));
  }

  return response.data;
}

export async function getDealInspector(dealId: string) {
  const url = `${baseUrl}api/admin/deal-inspector/${dealId}`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${bridgeJwt}`
  });
  if (!response.data || response.hasError) {
    return Promise.reject(new Error(response?.statusCode?.toString()));
  }
  const data: DealInspectorResponse = response.data;
  return data;
}
