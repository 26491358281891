import {
  LDContext,
  LDProvider,
  LDReactOptions,
  useLDClient
} from "launchdarkly-react-client-sdk";
import { getActiveFlags } from "@coxauto-ui/core/third-party/launch-darkly";
import { ModuleName, getEnvConfig } from "@coxauto-ui/core/get-env";

/* eslint-disable-next-line */
export interface CoreLdProviderProps {
  children: React.ReactNode;
  user?: LDContext;
  module: ModuleName;
}

const reactOptions: LDReactOptions = {
  useCamelCaseFlagKeys: false
};

const flags = getActiveFlags("cxm");

export function CoreLdProvider({
  user,
  children,
  module
}: CoreLdProviderProps) {
  const clientSideID = getEnvConfig(module)["LD_CLIENT_SIDE_ID"];
  const ldClient = useLDClient();

  if (ldClient || !user) {
    return <div data-testid="no-ld-provider-added">{children}</div>;
  }

  return (
    <LDProvider
      clientSideID={clientSideID}
      flags={flags}
      reactOptions={reactOptions}
      user={user}
    >
      <div data-testid="add-ld-provider">{children}</div>
    </LDProvider>
  );
}

export default CoreLdProvider;
