import { adminConfigLocal } from "./config/admin/admin-config-local";
import { adminConfigNp } from "./config/admin/admin-config-np";
import { adminConfigProd } from "./config/admin/admin-config-prod";
import { communicationsConfigLocal } from "./config/communications/communications-config-local";
import { communicationsConfigNp } from "./config/communications/communications-config-np";
import { communicationsConfigProd } from "./config/communications/communications-config-prod";
import { customerConfigLocal } from "./config/customer/customer-config-local";
import { customerConfigNp } from "./config/customer/customer-config-np";
import { customerConfigProd } from "./config/customer/customer-config-prod";
import { cxmConfigLocal } from "./config/cxm/cxm-config-local";
import { cxmConfigNp } from "./config/cxm/cxm-config-np";
import { cxmConfigProd } from "./config/cxm/cxm-config-prod";
import { ConfigMapper, ModuleName, ModuleUrlMapper, UrlMapper } from "./types";

const adminUrls: UrlMapper = {
  local: "http://localhost:4203/",
  np: "https://tenant-admin-mfp.np-pipelines.app.coxautoinc.com/",
  prod: "https://tenant-admin-mfp.pipelines.app.coxautoinc.com/"
};

const communicationsUrls: UrlMapper = {
  local: "http://localhost:4202/",
  np: "https://communications-mfp.np-pipelines.app.coxautoinc.com/",
  prod: "https://communications-mfp.pipelines.app.coxautoinc.com/"
};

const customerUrls: UrlMapper = {
  local: "http://localhost:4207/",
  np: "https://customer-mfp.np-pipelines.app.coxautoinc.com/",
  prod: "https://customer-mfp.pipelines.app.coxautoinc.com/"
};

const cxmUrls: UrlMapper = {
  local: "http://localhost:4200/",
  np: "https://np-pipelines.app.coxautoinc.com/",
  prod: "https://pipelines.app.coxautoinc.com/"
};

const adminConfigMapper: ConfigMapper = {
  [adminUrls.local]: adminConfigLocal,
  [adminUrls.np]: adminConfigNp,
  [adminUrls.prod]: adminConfigProd
};

const communicationsConfigMapper: ConfigMapper = {
  [communicationsUrls.local]: communicationsConfigLocal,
  [communicationsUrls.np]: communicationsConfigNp,
  [communicationsUrls.prod]: communicationsConfigProd
};

const customerConfigMapper: ConfigMapper = {
  [customerUrls.local]: customerConfigLocal,
  [customerUrls.np]: customerConfigNp,
  [customerUrls.prod]: customerConfigProd
};

const cxmConfigMapper: ConfigMapper = {
  [cxmUrls.local]: cxmConfigLocal,
  [cxmUrls.np]: cxmConfigNp,
  [cxmUrls.prod]: cxmConfigProd
};

const urlsMapper: ConfigMapper = {
  ...adminConfigMapper,
  ...communicationsConfigMapper,
  ...customerConfigMapper,
  ...cxmConfigMapper
};

const moduleUrlsMapper: ModuleUrlMapper = {
  admin: adminUrls,
  communications: communicationsUrls,
  customer: customerUrls,
  cxm: cxmUrls
};

export const getConfigByEnvironmentContext = (
  url: string,
  moduleName: ModuleName
) => {
  const envValues = urlsMapper[url];
  if (!envValues) {
    return;
  }
  const moduleUrls = moduleUrlsMapper[moduleName];
  const environment = envValues.MODULE_ENV;
  const newUrl = moduleUrls[environment];
  return urlsMapper[newUrl || ""];
};
