import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./app/app";

import { store } from "@coxauto-ui/ccxm/store";
import { TagManager } from "@coxauto-ui/core-third-party-gtm";
import { ErrorBoundary } from "@coxauto-ui/core/errors";
import FailureState from "./failure-state/failure-state";
import { NewRelic } from "@coxauto-ui/core/third-party/newrelic";
import getNewRelicConfig from "@coxauto-ui/ccxm/util/get-new-relic-config";
import getInitialGtmConfig from "@coxauto-ui/ccxm/util/get-initial-gtm-config";
import { CxThemeProvider } from "@coxauto-ui/core/cx";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";
import { interstateThemeRegistry } from "@interstate/components/InterstateTheming";
import { RoutingContextProvider } from "@coxauto-ui/ccxm-routing";
import { SecureRoute } from "@coxauto-ui/core/auth/secure-route";

const initialGtmConfig = getInitialGtmConfig();
const newRelicConfig = getNewRelicConfig();

TagManager.initialize(initialGtmConfig);
NewRelic.initialize(newRelicConfig);

const domNode = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(domNode!).render(
  <ErrorBoundary
    FallbackComponent={FailureState}
    onError={NewRelic.noticeError}
  >
    <RoutingContextProvider>
      <Provider store={store}>
        <CxThemeProvider themeName={"Cx"}>
          <InterstateThemeProvider
            applicationName="CRW"
            scopeName="CRW"
            themeRegistries={[interstateThemeRegistry]}
          >
            <ErrorBoundary
              FallbackComponent={FailureState}
              onError={NewRelic.noticeError}
            >
              <BrowserRouter>
                <StrictMode>
                  <Routes>
                    <Route element={<SecureRoute />} path="*">
                      <Route element={<App />} path={"*"} />
                    </Route>
                  </Routes>
                </StrictMode>
              </BrowserRouter>
            </ErrorBoundary>
          </InterstateThemeProvider>
        </CxThemeProvider>
      </Provider>
    </RoutingContextProvider>
  </ErrorBoundary>
);
