import { EnvConfig } from "../../types";

export const cxmConfigNp: EnvConfig = {
  MODULE_NAME: "cxm",
  MODULE_ENV: "np",
  ENV: "nonprod",
  LD_CLIENT_SIDE_ID: "63583be10d275b11a1f32f7b",
  BRIDGE_SCOPES: [
    "bridge.signin",
    "openid",
    "cxm-private.cxm-ui-bff.insights.read",
    "cxm-private.cxm-ui-bff.insights.read"
  ],
  BRIDGE_RUNTIME: "NON_PRODUCTION",
  BRIDGE_CLIENT_ID: "0oaj7hbzoiULXaWM3357",
  BRIDGE_BAR_ENV: "qa",
  GTM_ENV: "nonprod",
  NEW_RELIC_ACCOUNT_ID: "2755601",
  NEW_RELIC_AGENT_ID: "1120109754",
  NEW_RELIC_APP_ID: "1120109754",
  NEW_RELIC_LICENSE_KEY: "NRJS-c686c3bcfdcd1bd390a",
  NEW_RELIC_ALLOWED_ORIGINS: ["https://cxm.ccxmnp.cloud/"],
  BASE_URL: "https://cxm-ui-bff-api.np-pipelines.app.coxautoinc.com/",
  REAL_TIME_URL: "https://cxm-realtime-api.np-pipelines.app.coxautoinc.com/",
  MANIFEST_URL:
    "https://np-pipelines.app.coxautoinc.com/module-federation.manifest.json"
};
