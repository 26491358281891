import { replyLogACallInputHeader } from "../testIds";
import styled from "styled-components";
import MessageCallContactedInput from "./message-call-contacted-input";
import { MessageCallTypeInput } from "./message-call-type-input";
import { ChannelValueSelector } from "./channel-value-selector";
import classNames from "classnames";
import { useComponentWidth } from "@coxauto-ui/communications-util";
/* eslint-disable-next-line */

const StyledMessageCallInfo = styled.div`
  flex-basis: 100%;
`;

const StyledMessageCallInfoInputs = styled.div`
  padding-bottom: 10px;

  &.large {
    display: flex;
    gap: 8px;
  }

  .control-label {
    white-space: nowrap;
  }
`;

export function MessageCallInfo() {
  const [width, ref] = useComponentWidth();

  return (
    <StyledMessageCallInfo data-testid={replyLogACallInputHeader} ref={ref}>
      <StyledMessageCallInfoInputs
        className={classNames({ large: width > 600 })}
      >
        <MessageCallTypeInput />
        <MessageCallContactedInput />
      </StyledMessageCallInfoInputs>
      <ChannelValueSelector isDisabled={false} />
    </StyledMessageCallInfo>
  );
}

export default MessageCallInfo;
