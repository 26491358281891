export type CXMFlagNames =
  | "nx.cxm.test"
  | "nx.cxm.show-vin-employee-management"
  | "nx.cxm.show-user-management"
  | "nx.cxm.edit-dealer-settings"
  | "nx.cxm.show-trending-duplicates-tab"
  | "nx.cxm.kill.crw-deallist-persona"
  | "nx.cxm.show-dealtrack"
  | "nx.cxm.show-dealcomments"
  // eslint-disable-next-line @cspell/spellchecker
  | "nx.cxm.show-addadeal-button"
  | "nx.cxm.show-data-purge-message";

export type TestFlagNames = "devTestFlag";

type AllFlagsNames = TestFlagNames | CXMFlagNames;

type AppNames = "test" | "cxm" | "customer-inbox";

type Flags = Array<{ name: AllFlagsNames; apps: Array<AppNames> }>;

const FLAGS: Flags = [
  /** Mock flag only for test page */
  {
    name: "devTestFlag",
    apps: ["test"]
  },
  {
    /** nx.cxm.test
     * Intergration test toggle for validating LD inside CXM */
    name: "nx.cxm.test",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.show-vin-employee-management
     * Show list employees list button  */
    name: "nx.cxm.show-vin-employee-management",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.show-user-management
     * Show User management */
    name: "nx.cxm.show-user-management",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.edit-dealer-settings
     * Show dealer management visible data columns */
    name: "nx.cxm.edit-dealer-settings",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.kill.crw-deallist-persona
     * hide crw deal list persona */
    name: "nx.cxm.kill.crw-deallist-persona",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.show-dealtrack
     * Show deal track */
    name: "nx.cxm.show-dealtrack",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.show-dealcomments
     * Show deal comments */
    name: "nx.cxm.show-dealcomments",
    apps: ["cxm"]
  },
  {
    // eslint-disable-next-line @cspell/spellchecker
    /** nx.cxm.show-addadeal-button
     * Show create new deal button */
    // eslint-disable-next-line @cspell/spellchecker
    name: "nx.cxm.show-addadeal-button",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.show-trending-duplicates-tab
     * show trending duplicate tab */
    name: "nx.cxm.show-trending-duplicates-tab",
    apps: ["cxm"]
  },
  {
    /** nx.cxm.show-data-purge-message
     * Show data purge message */
    name: "nx.cxm.show-data-purge-message",
    apps: ["cxm"]
  }
];

export const getActiveFlags = (appName: AppNames) => {
  return FLAGS.reduce((acc, { name, apps }, i) => {
    if (apps.some(app => app === appName)) {
      return {
        ...acc,
        [name]: true
      };
    } else {
      return acc;
    }
  }, {});
};
