import { EnvConfig } from "../../types";

export const adminConfigLocal: EnvConfig = {
  ENV: "nonprod",
  MODULE_NAME: "admin",
  MODULE_ENV: "local",
  LD_CLIENT_SIDE_ID: "63583be10d275b11a1f32f7b",
  BRIDGE_SCOPES: [
    "bridge.signin",
    "openid",
    "cxm-private.cxm-ui-bff.insights.read",
    "cxm-private.cxm-ui-bff.insights.read"
  ],
  BRIDGE_RUNTIME: "NON_PRODUCTION",
  BRIDGE_CLIENT_ID: "0oaj7hbzoiULXaWM3357",
  BASE_URL: "https://cxm-ui-bff-api.np-pipelines.app.coxautoinc.com/",
  REAL_TIME_URL: "https://cxm-realtime-api.np-pipelines.app.coxautoinc.com/",
  MANIFEST_URL: "http://localhost:4200/module-federation.manifest.json"
};
