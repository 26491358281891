import { BridgeAuthClient } from "@bridge/auth-js";
import { BridgeRuntime, bridge } from "@bridge/okta-auth";
import { getEnvConfig } from "@coxauto-ui/core/get-env";
import { AdminEnvironmentValues } from "@coxauto-ui/tenant/interfaces";

const { BRIDGE_SCOPES, BRIDGE_RUNTIME, BRIDGE_CLIENT_ID } =
  getEnvConfig<AdminEnvironmentValues>("admin");

const configuration = {
  idp: bridge(BridgeRuntime[BRIDGE_RUNTIME]),
  clientId: BRIDGE_CLIENT_ID,
  scopes: BRIDGE_SCOPES,
  redirectUri: `${window.location.origin}/`
};

const authClient = BridgeAuthClient.buildFrom(configuration, {
  init: async authClient => {
    const hasExpiredAccessToken = await authClient.hasExpiredAccessToken();

    if (hasExpiredAccessToken) {
      authClient.clearStorage();
    }
  }
});

export function overrideGetAuth(getAuthOverride: () => Promise<string>) {
  authClient.getAuth = getAuthOverride;
}

export default authClient;
