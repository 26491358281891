import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";

const displayTextForReplyHeader: Record<ConversationTypeEnum, string | null> = {
  [ConversationTypeEnum.ALL]: null,
  [ConversationTypeEnum.CHAT]: null,
  [ConversationTypeEnum.EMAIL]: "Email Customer",
  [ConversationTypeEnum.NONE]: null,
  [ConversationTypeEnum.PHONE]: "Log a Call",
  [ConversationTypeEnum.TEXT]: "Text Customer"
};

export function deriveDisplayTextForReplyHeader(
  conversationType: ConversationTypeEnum
) {
  return displayTextForReplyHeader[conversationType];
}
