export interface Conversation {
  nextItem: number | null;
  conversation: ConversationItem[];
  recentContactText: string;
  recentContactEmail: string;
  xSignature: string;
}

export interface ConversationItem {
  senderName: string;
  senderContactInfo: string | undefined;
  recipientName?: string;
  recipientContactInfo?: string;
  content: ConversationContentType;
  age: string;
  direction: ConversationDirection;
  type: ConversationTypeEnum;
  key: number;
  date: string;
  subject?: string;
  emailAddress: string;
  phoneNumber: string;
  eventResult: EventResultEnum;
  attachments: Attachment[];
}

export interface Attachment {
  attachmentId: string;
  fileName: string;
  type: AttachmentTypeEnum;
  url?: string;
}

export interface UpdateConversationItemResult {
  updatedItem: ConversationItem;
  wasUpdated: boolean;
}

export interface Interaction {
  text: string;
  shortSubject?: string;
}

export interface ReplyOption {
  type: ConversationTypeEnum;
  replyInline: boolean;
  replyUrl?: string;
}

export const enum ConversationViewTypeEnum {
  DETAIL = "detail",
  LIST = "list"
}

// ExternalContent
export interface ExternalInteraction extends Interaction {
  externalUrl: string;
}

export interface PhoneInteraction extends Interaction {
  callUrl: string;
}

export enum ConversationDirection {
  Inbound = "inbound",
  Outbound = "outbound"
}

export type ConversationContentType =
  | Interaction
  | ExternalInteraction
  | PhoneInteraction;

export type setSelectedReplyOption = (replyOption: ReplyOption) => void;

export type setConversationItem = (
  current:
    | ConversationItem[]
    | ((previousValue: ConversationItem[]) => ConversationItem[])
) => void;

export type onSetConversationItem = (items: ConversationItem[]) => void;

export type onClickMessage = (
  selectedMessage: ConversationItem
) => Promise<void>;

export enum ConversationTypeEnum {
  ALL = "all",
  TEXT = "text",
  PHONE = "phone",
  EMAIL = "email",
  CHAT = "chat",
  NONE = "unknown"
}

export interface CommunicationItemsMerge {
  items: ConversationItem[];
  hasNewItems: boolean;
}

export interface GroupedMessages {
  key: string;
  messages: ConversationItem[];
}

export const enum EventResultEnum {
  FAILED = "Failed",
  INPROGRESS = "In Progress",
  LEFTMESSAGE = "Left Message",
  NOANSWER = "No Answer",
  SPOKE = "Spoke",
  SUCCESS = "Success",
  UNKNOWN = "Unknown"
}

export const enum AttachmentTypeEnum {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  OTHER = "OTHER"
}

export interface OccConfig {
  disableReply?: boolean;
  username: string;
  defaultTab?: ConversationTypeEnum;
  messageContent?: string;
}

export const enum ContactEnum {
  YES = "YES",
  NO = "NO",
  MACHINE_ANSWERED = "MACHINE_ANSWERED"
}

export interface DealerCommunicationDetails {
  dealerPhoneNumber: DealerPhoneNumber;
  userPhoneNumbers: UserPhoneNumber[];
}

export interface DealerPhoneNumber {
  phoneNumber: string;
}

export interface UserPhoneNumber {
  phoneNumber: string;
  userFullName: string;
}
