import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ContactDetails,
  ConversationTypeEnum,
  DealerCommunicationDetails,
  Environment,
  OccConfig,
  ReplyOption,
  SystemOfRecord
} from "@coxauto-ui/communications-interfaces";
import { ConversationPane } from "./conversation-pane";
import {
  GetDealerCommunicationDetailsByConsumerIdResponse,
  getContactDetails,
  getCrmGenAIDealer,
  getCrmGenAIDealerRequest,
  getDealerCommunicationDetailsByConsumerId
} from "@coxauto-ui/communications-api";
import { ConversationContext } from "./context";
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import authClient from "@coxauto-ui/core-auth-get-auth-client-customer-conversation";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";

import { interstateThemeRegistry } from "@interstate/components/InterstateTheming/themes/interstateThemeRegistry";
export interface ConversationProps {
  customerId: string;
  customerSystem?: SystemOfRecord;
  /** Determines which lead to associate communications to */
  selectedLeadId?: string;
  /** When `true`, displays the title above the customer conversation*/
  showCustomerInfo?: boolean;
  /** Title override to display when `showCustomerInfo` is true. Defaults to customer name when undefined */
  title?: string;
  occConfig: OccConfig;
  getAuthOverride?: () => Promise<string>;
  env?: Environment;
}

const supportedGenerativeAIDomains = [
  "localhost",
  "qa-vinsolutions.app.coxautoinc.com",
  "vinsolutions.app.coxautoinc.com"
];

export function CustomerConversationApp({
  customerId,
  customerSystem,
  selectedLeadId,
  showCustomerInfo = true,
  title,
  occConfig = {
    username: "",
    disableReply: false,
    defaultTab: undefined,
    messageContent: undefined
  },
  getAuthOverride,
  env = Environment.none
}: ConversationProps) {
  const [customerName, setCustomerName] = useState("");
  const [replyOptions, setReplyOptions] = useState<ReplyOption[]>([]);
  const [activeListTab, setActiveListTab] = useState(ConversationTypeEnum.ALL);
  const [error, setError] = useState("");
  const [disableReply, setDisableReply] = useState(occConfig.disableReply);
  const [isGenerativeAIEnabled, setIsGenerativeAIEnabled] = useState(false);
  const [getUserName, setGetUserName] = useState<() => string>(() => {
    return () => {
      return occConfig.username;
    };
  });
  const [contactDetails, setContactDetails] = useState<ContactDetails | null>(
    null
  );
  const [dealerCommunicationDetails, setDealerCommunicationDetails] =
    useState<DealerCommunicationDetails | null>(null);

  useEffect(() => {
    if (getAuthOverride) {
      authClient.getAuth = getAuthOverride;
    }
  });

  const setCustomerConversationContext = useCallback(async () => {
    const request = {
      consumerId: customerId,
      consumerRecordSystem: customerSystem || SystemOfRecord.COMMON
    };

    try {
      const [contactDetails, dealerCommunicationDetails]: [
        ContactDetails,
        GetDealerCommunicationDetailsByConsumerIdResponse
      ] = await Promise.all([
        getContactDetails(request, env),
        getDealerCommunicationDetailsByConsumerId(request, env)
      ]);

      setReplyOptions(contactDetails.replyOptions);
      setCustomerName(contactDetails.name.fullName);
      setContactDetails(contactDetails);
      setDealerCommunicationDetails(dealerCommunicationDetails);
    } catch (error: any) {
      setError(error?.message || "An unexpected error occurred.");
    }
  }, [customerId, customerSystem, env]);

  useEffect(() => {
    setError("");
  }, [customerId]);

  useEffect(() => {
    setCustomerConversationContext();
  }, [setCustomerConversationContext]);

  useEffect(() => {
    if (supportedGenerativeAIDomains.includes(window.location.hostname)) {
      (async function () {
        try {
          const request: getCrmGenAIDealerRequest = {
            consumerId: customerId,
            consumerRecordSystem: customerSystem || SystemOfRecord.COMMON
          };

          const response = await getCrmGenAIDealer(request, env);

          if (response?.enabled) {
            setIsGenerativeAIEnabled(true);
          } else {
            setIsGenerativeAIEnabled(false);
          }
        } catch (error: any) {
          // silently fail the buttons. ha ha ha.
        }
      })();
    }
  }, [customerId, customerSystem, env]);

  useEffect(() => {
    TagManager.event({
      event: "productEvent",
      eventName: "Conversation Opened",
      eventProperties: {
        packagedBusinessCapability: "OCC Inbox",
        commonCustomerId: customerId,
        communicationChannel: undefined,
        replyContext: undefined,
        errorMessage: undefined
      }
    });

    return () => {
      TagManager.event({
        event: "productEvent",
        eventName: "Conversation Closed",
        eventProperties: {
          packagedBusinessCapability: "OCC Inbox",
          commonCustomerId: customerId,
          communicationChannel: undefined,
          replyContext: undefined,
          errorMessage: undefined
        }
      });
    };
  }, [customerId]);

  useEffect(() => {
    setDisableReply(occConfig.disableReply);
    setGetUserName(() => () => occConfig.username);
  }, [occConfig]);

  const providerValue = useMemo(() => {
    return {
      activeListTab,
      autoLeadId: selectedLeadId || null,
      customerId,
      customerSystem: customerSystem || SystemOfRecord.COMMON,
      customerName,
      dealerCommunicationDetails,
      disableReply,
      env,
      error,
      isGenerativeAIEnabled,
      replyOptions,
      showCustomerInfo,
      title,
      contactDetails,
      occConfig,
      getUserName,
      setActiveListTab,
      setError
    };
  }, [
    activeListTab,
    customerId,
    customerName,
    customerSystem,
    dealerCommunicationDetails,
    disableReply,
    env,
    error,
    isGenerativeAIEnabled,
    replyOptions,
    selectedLeadId,
    showCustomerInfo,
    title,
    contactDetails,
    occConfig,
    getUserName,
    setActiveListTab,
    setError
  ]);

  return (
    <InterstateThemeProvider
      applicationName="test"
      scopeName="test"
      themeRegistries={[interstateThemeRegistry]}
    >
      <ConversationContext.Provider value={providerValue}>
        <ConversationPane />
      </ConversationContext.Provider>
    </InterstateThemeProvider>
  );
}

export default CustomerConversationApp;
