import React, { useEffect } from "react";
import authClient from "@coxauto-ui/core/auth/get-auth-client-tenant-admin";
import RealTimeInfo from "./tenant-admin-realtime-info";
import { SignalRProvider } from "@coxauto-ui/core/real-time-signalr";
import { getEnvConfig } from "@coxauto-ui/core/get-env";
import { AdminEnvironmentValues } from "@coxauto-ui/tenant/interfaces";

const { REAL_TIME_URL: url } = getEnvConfig<AdminEnvironmentValues>("admin");

export const TenantAdminRealTimeInfo = () => {
  useEffect(() => {
    document.title = "Real Time Info";
  }, []);
  return (
    <SignalRProvider
      accessTokenFactory={() => authClient.getAuth()}
      url={`${url}Hubs/Deals`}
    >
      <RealTimeInfo />
    </SignalRProvider>
  );
};
