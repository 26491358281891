import {
  ConversationContentType,
  ConversationDirection,
  ConversationTypeEnum,
  EventResultEnum
} from "@coxauto-ui/communications-interfaces";
import {
  derivePhoneCallSubject,
  isExternalMessageContent,
  isPhoneMessageContent
} from "@coxauto-ui/communications-util";
import { IconShare } from "@coxauto-ui/core-cx";
import { useContext, useState } from "react";
import styled from "styled-components";
import { ConversationContext } from "../context";
import {
  conversationsListItemAudioTestId,
  messageItemBodyId,
  messageItemExternalContentHyperlinkIconId,
  messageItemExternalContentHyperlinkId,
  messageItemRecipientContactInfoId,
  messageItemSubjectId
} from "../testIds";

// eslint-disable-next-line
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import MessageItemContentBottom from "./item-content-bottom";

const StyledHyperlink = styled.a`
  color: inherit;
  display: block;
  width: fit-content;
  height: 2em;
  border: 1px solid black;
  border-radius: 0.5em;
  padding: 0.2em;
  background-color: #e3e3e3;
  color: #000;
  &:hover,
  &:focus {
    color: #000;
  }
`;
const StyledAudio = styled.audio`
  display: block;
  cursor: auto;
  height: 2em;
`;
const StyledSeparator = styled.div`
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledSubject = styled.div`
  display: inline;
  font-weight: normal;
`;

const StyledPhoneCallSubject = styled.div`
  font-weight: bolder;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledRecipientContact = styled.div`
  display: inline;
  font-weight: normal;
`;

const StyledIconShare = styled(IconShare)`
  vertical-align: middle;
`;

const StyledNormalText = styled.span`
  font-weight: normal;
`;

export interface MessageItemContentProps {
  type: ConversationTypeEnum;
  content: ConversationContentType;
  contentId: number;
  eventResult: EventResultEnum;
  conversationDirection: ConversationDirection;
  recipientContactInfo?: string;
  numberOfAttachments: number;
}

export function MessageItemContent({
  type,
  content,
  contentId,
  eventResult,
  conversationDirection,
  recipientContactInfo,
  numberOfAttachments
}: MessageItemContentProps) {
  const { customerId } = useContext(ConversationContext);
  const [isFirstPlay, setIsFirstPlay] = useState(true);

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const onPlayPhoneCallEvent = () => {
    if (isFirstPlay) {
      TagManager.event({
        event: "productEvent",
        eventName: "Call Recording Played",
        eventProperties: {
          packagedBusinessCapability: "OCC Inbox",
          commonCustomerId: customerId,
          communicationChannel: undefined,
          replyContext: undefined,
          errorMessage: undefined
        }
      });
      setIsFirstPlay(false);
    }
  };

  return (
    <div>
      {isExternalMessageContent(content) && (
        <StyledHyperlink
          data-testid={messageItemExternalContentHyperlinkId + contentId}
          href={content.externalUrl}
          rel="noreferrer"
          target="_blank"
          onClick={onClick}
        >
          Click to open{" "}
          <StyledIconShare
            data-testid={messageItemExternalContentHyperlinkIconId + contentId}
          />
        </StyledHyperlink>
      )}
      {content.shortSubject && type !== ConversationTypeEnum.PHONE && (
        <>
          <b>To: </b>
          <StyledRecipientContact
            data-testid={messageItemRecipientContactInfoId + contentId}
            title={recipientContactInfo}
          >
            {recipientContactInfo}
          </StyledRecipientContact>
          <StyledSeparator />
          <b>Subject: </b>
          <StyledSubject
            data-testid={messageItemSubjectId + contentId}
            title={content.shortSubject}
          >
            {content.shortSubject}
          </StyledSubject>
          <StyledSeparator />
        </>
      )}
      {type === ConversationTypeEnum.PHONE && (
        <StyledPhoneCallSubject
          data-testid={messageItemSubjectId + contentId}
          title={"phonecall"}
        >
          {derivePhoneCallSubject(conversationDirection, eventResult)}
        </StyledPhoneCallSubject>
      )}
      <StyledNormalText data-testid={messageItemBodyId + contentId}>
        {content.text}
      </StyledNormalText>
      {type === ConversationTypeEnum.PHONE &&
        isPhoneMessageContent(content) &&
        content.callUrl > "" && (
          <StyledAudio
            controls={true}
            data-testid={conversationsListItemAudioTestId + contentId}
            src={content.callUrl}
            onPlay={onPlayPhoneCallEvent}
          >
            Your browser does not support audio.
          </StyledAudio>
        )}
      {type === ConversationTypeEnum.EMAIL && (
        <MessageItemContentBottom
          contentId={contentId}
          eventResult={eventResult}
          numberOfAttachments={numberOfAttachments}
        />
      )}
    </div>
  );
}

export default MessageItemContent;
