import { interstateThemeRegistry } from "@interstate/components/InterstateTheming";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";
import { useMemo } from "react";
import { Provider, shallowEqual, useSelector } from "react-redux";
import { getUserProfileState } from "@coxauto-ui/ccxm/store";
import { RoutingContextProvider } from "@coxauto-ui/ccxm-routing";
import { createGlobalStyle } from "styled-components";
import { adminStore } from "@coxauto-ui/tenant/store";
import { ToastContainer } from "@interstate/components/Toast";
import { CoreLdProvider } from "@coxauto-ui/core/ld-provider";

/* eslint-disable-next-line */
export interface TenantAdminAppProps {
  children: React.ReactNode;
  useHostStore?: boolean;
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f6f7 !important;
  }
  .Toastify__toast-container {
    transform: translate(0,18px);
  }
  `;

const themeRegistries = [interstateThemeRegistry];

export function TenantAdminApp({
  children,
  useHostStore = false
}: TenantAdminAppProps) {
  const { bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName } =
    useSelector(getUserProfileState, shallowEqual);

  const user = useMemo(() => {
    return {
      key: bridgeUserId,
      name: bridgeUserName,
      custom: {
        dealerGroupId,
        dealerGroupName
      }
    };
  }, [bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName]);

  const app = (
    <InterstateThemeProvider
      applicationName="CRW"
      scopeName="CRW"
      themeRegistries={themeRegistries}
    >
      <RoutingContextProvider>
        <CoreLdProvider module={"admin"} user={user}>
          {children}
        </CoreLdProvider>
      </RoutingContextProvider>
      <GlobalStyle />
      <ToastContainer />
    </InterstateThemeProvider>
  );

  return useHostStore ? app : <Provider store={adminStore}>{app}</Provider>;
}

export default TenantAdminApp;
