import { tagManagerArgs } from "@coxauto-ui/core-interfaces-third-party-gtm";
import { CxmEnvironmentValues } from "@coxauto-ui/ccxm/interfaces";
import { getEnvConfig } from "@coxauto-ui/core/get-env";

const getInitialGtmConfig = (): tagManagerArgs => {
  const { GTM_ENV } = getEnvConfig<CxmEnvironmentValues>("cxm");
  return {
    dataLayer: {
      dataLayerReady: false,
      eventProperties: {
        listContext: "none",
        commonCustomerId: "",
        errorMessage: undefined,
        actionContext: "none",
        listPlacement: 0
      },
      common: {
        dataLayerVersion: 2,
        application: {
          name: "CxM",
          version: "1.0",
          environment: GTM_ENV,
          isProduction: GTM_ENV === "prod"
        }
      }
    },
    dataLayerName: "dataLayer",
    gtmId: "GTM-MKS8WZ3"
  };
};

export default getInitialGtmConfig;
