import "antd/dist/antd.css";
import "./app.scss";
import { useMemo } from "react";
import { AddScriptTag } from "@coxauto-ui/core-helpers";
// Todo we need to delete this when we finish the migration to interstate
import { ToastContainer as CXToastContainer } from "@coxauto-ui/core-cx";
import { TenantAdminViewAsBanner } from "@coxauto-ui/tenant/admin";
import { createGlobalStyle } from "styled-components";
import Routes from "./routes/routes";
import {
  BridgeBarScriptProps,
  getBridgeBarScript
} from "@coxauto-ui/ccxm/util/get-bridge-bar-script";
import { ToastContainer } from "@interstate/components/Toast";
import AppWrapper from "./app-wrapper";
import getSessionId from "@coxauto-ui/util/get-session-identifier";
import { getEnvConfig } from "@coxauto-ui/core/get-env";
import { CxmEnvironmentValues } from "@coxauto-ui/ccxm/interfaces";

const GlobalStyle = createGlobalStyle`
  .MuiPopper-root {
    z-index: 4;
  }
  .Toastify__toast-container {
    transform: translate(0,18px);
  }
  body {
    background-color: #fff;
  }
`;

export function App() {
  const bridgeScript = useMemo(() => {
    const sessionId = getSessionId();

    const { BRIDGE_BAR_ENV } = getEnvConfig<CxmEnvironmentValues>("cxm");

    const config: BridgeBarScriptProps = {
      sessionId,
      environment: BRIDGE_BAR_ENV,
      solutionGroup: "CMD"
    };

    return getBridgeBarScript(config);
  }, []);

  return (
    <AppWrapper>
      <bridge-bar data-testid="bridge-bar" />
      <AddScriptTag
        async
        src="https://assets.bridge.coxautoservices.com/bridge-bar.js"
        testid="bridge-bar-script"
      />
      <AddScriptTag script={bridgeScript} testid="bridge-bar-inline-script" />
      <TenantAdminViewAsBanner />
      <Routes />
      <CXToastContainer />
      <ToastContainer />
      <GlobalStyle />
    </AppWrapper>
  );
}

export default App;
