import { getConfigByEnvironmentContext } from "./url-mapper";
import { ModuleName } from "./types";

declare const __webpack_public_path__: string;

/**
 * Retrieves the environment configuration for the current module and environment.
 *
 * This function determines the module and environment based on the execution context
 * and returns an object containing the relevant environment variables.
 *
 * @param {ModuleName} [moduleName] - Module Name
 * @returns {T} The environment configuration object.
 *
 * @example
 * // Retrieve configuration for a module named
 * const envConfigWithContext = getEnvConfig<MyConfig>('cxm');
 * console.log(envConfigWithContext.API_URL);
 */
export const getEnvConfig = <T extends Record<string, any>>(
  moduleName: ModuleName
): T => {
  const url = __webpack_public_path__;
  const config = getConfigByEnvironmentContext(url, moduleName);

  if (!config) {
    throw new Error(`Environment not found for the current url: ${url}`);
  }

  return config as unknown as T;
};

export default getEnvConfig;
