import { EnvConfig } from "../../types";

export const cxmConfigProd: EnvConfig = {
  MODULE_NAME: "cxm",
  MODULE_ENV: "prod",
  ENV: "prod",
  LD_CLIENT_SIDE_ID: "63583be10d275b11a1f32f7c",
  BRIDGE_SCOPES: [
    "bridge.signin",
    "openid",
    "cxm-private.cxm-ui-bff.insights.read",
    "cxm-private.cxm-ui-bff.insights.read"
  ],
  BRIDGE_RUNTIME: "PRODUCTION",
  BRIDGE_CLIENT_ID: "0oaj7hcoekerOmcDu357",
  BRIDGE_BAR_ENV: "prod",
  GTM_ENV: "prod",
  NEW_RELIC_ACCOUNT_ID: "1950602",
  NEW_RELIC_AGENT_ID: "594364944",
  NEW_RELIC_APP_ID: "594364944",
  NEW_RELIC_LICENSE_KEY: "e6afbc9257",
  NEW_RELIC_ALLOWED_ORIGINS: ["https://swblpftdv-mts01.dev.vinstickers.com"],
  BASE_URL: "https://cxm-ui-bff-api.pipelines.app.coxautoinc.com/",
  REAL_TIME_URL: "https://cxm-realtime-api.pipelines.app.coxautoinc.com/",
  MANIFEST_URL:
    "https://pipelines.app.coxautoinc.com/module-federation.manifest.json"
};
