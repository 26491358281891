import { CxmEnvironmentValues } from "@coxauto-ui/ccxm/interfaces";
import { getEnvConfig } from "@coxauto-ui/core/get-env";

const getNewRelicConfig = () => {
  const {
    NEW_RELIC_ACCOUNT_ID,
    NEW_RELIC_AGENT_ID,
    NEW_RELIC_APP_ID,
    NEW_RELIC_LICENSE_KEY,
    NEW_RELIC_ALLOWED_ORIGINS
  } = getEnvConfig<CxmEnvironmentValues>("cxm");
  return {
    accountID: NEW_RELIC_ACCOUNT_ID,
    trustKey: "1190893",
    agentID: NEW_RELIC_AGENT_ID,
    licenseKey: NEW_RELIC_LICENSE_KEY,
    applicationID: NEW_RELIC_APP_ID,
    corsUseNewRelicHeader: true,
    excludeNewRelicHeader: false,
    corsUseTraceContextHeaders: true,
    distributedTracing: true,
    corsEnabled: true,
    allowedOrigins: NEW_RELIC_ALLOWED_ORIGINS
  };
};

export default getNewRelicConfig;
