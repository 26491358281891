import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import { useContext, useEffect, useState } from "react";
import { ConversationDetailsContext } from "../context";
import { replyCallTypeDropDownTestId } from "../testIds";
import { ConversationDirection } from "@coxauto-ui/communications-interfaces";

/* eslint-disable-next-line */
export interface MessageCallTypeInputProps {}

export function MessageCallTypeInput(props: MessageCallTypeInputProps) {
  const { messageCallType, setMessageCallType } = useContext(
    ConversationDetailsContext
  );
  const [callType, setCallType] = useState(ConversationDirection.Inbound);

  useEffect(() => {
    if (callType !== messageCallType) setMessageCallType(callType);
  }, [callType, messageCallType, setMessageCallType]);

  const onSelectedInputChange = (
    e: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    const direction =
      e.target.value === "Inbound"
        ? ConversationDirection.Inbound
        : ConversationDirection.Outbound;

    setCallType(direction);
    setMessageCallType(direction);
  };

  return (
    <SelectInput
      required
      data-testid={replyCallTypeDropDownTestId}
      displayDeselectOption={false}
      htmlId="messageCallTypeInput"
      label="Call Type"
      name="CallType"
      options={[
        {
          label: "Inbound",
          value: ConversationDirection.Inbound
        },
        {
          label: "Outbound",
          value: ConversationDirection.Outbound
        }
      ]}
      value={callType}
      onChange={onSelectedInputChange}
    />
  );
}

export default MessageCallTypeInput;
