import {
  ConversationDirection,
  EventResultEnum
} from "@coxauto-ui/communications-interfaces";

export function derivePhoneCallSubject(
  conversationDirection: ConversationDirection,
  eventResult: EventResultEnum
) {
  return conversationDirection === ConversationDirection.Inbound
    ? deriveInboundPhoneCallSubject(eventResult)
    : deriveOutboundPhoneCallSubject(eventResult);
}

export function deriveInboundPhoneCallSubject(eventResult: EventResultEnum) {
  switch (eventResult) {
    case EventResultEnum.LEFTMESSAGE:
      return "Customer left voicemail";
    case EventResultEnum.NOANSWER:
      return "Missed customer's call";
    case EventResultEnum.SPOKE:
      return "Spoke with customer";
    default: {
      return eventResult;
    }
  }
}

export function deriveOutboundPhoneCallSubject(eventResult: EventResultEnum) {
  switch (eventResult) {
    case EventResultEnum.LEFTMESSAGE:
      return "Left customer voicemail";
    case EventResultEnum.NOANSWER:
      return "Customer did not answer";
    case EventResultEnum.SPOKE:
      return "Spoke with customer";
    default: {
      return eventResult;
    }
  }
}
