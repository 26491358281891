import { ReactNode } from "react";
import authClient from "@coxauto-ui/core/auth/get-auth-client-tenant-admin";

import { SignalRProvider } from "@coxauto-ui/core/real-time-signalr";
import { LogLevel } from "@microsoft/signalr";
import { getEnvConfig } from "@coxauto-ui/core/get-env";
import { CxmEnvironmentValues } from "@coxauto-ui/ccxm/interfaces";

export interface CcxmInsightsListRealTimeProps {
  children: ReactNode;
}

const { REAL_TIME_URL: url } = getEnvConfig<CxmEnvironmentValues>("cxm");

export function CcxmInsightsListRealTime({
  children
}: CcxmInsightsListRealTimeProps) {
  return (
    <SignalRProvider
      accessTokenFactory={() => authClient.getAuth()}
      logLevel={LogLevel.None}
      url={`${url}Hubs/Deals`}
    >
      <div data-testid="ccxm-insights-list-real-time">{children}</div>
    </SignalRProvider>
  );
}

export default CcxmInsightsListRealTime;
